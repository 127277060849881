div.page, div.overlay, div.centering {
    display: flex;
    flex-direction: column;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

div.overlay, div.centering {
    justify-content: center;
}

div.overlay {
    background: rgba(0, 0, 0, 0.05);
}

div.popup-box {
    /* TODO: Move coloring to a different file maybe, one file for layout control, one file for colors. */
    background: #FFFFFF;
    border-radius: 0.5em;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.3);
    padding: 0.5em;
    margin: 4ch;

    display: flex;
    flex-flow: column;

    align-items: center;
}

div.popup-box > div.popup-box-item {
    cursor: pointer;
    margin-top: 0.25em;
    margin-bottom: 0.25em;
}

nav {
    order: 1;

    display: flex;
    flex-flow: row;
    align-items: center;

    background: linear-gradient(to bottom, #4455aa 75%, #334499 100%);
    color: #FFFFFF;

    /* Fixed height nav bar for consistency between pages. */
    min-height: 2.3em;
    max-height: 2.3em;
    overflow: hidden;
}
nav > div.center {
    flex-grow: 100;
}
/* Downside to this is that you can't just drop all items in the nav bar
   center if you're lazy; you'll get double padding unless the PageNav
   implementation selectively hides empty left/right sections. */
nav > div > * {
    padding: 0.1em;
}

/* TODO: need a better name. What was the name for the workspace in Win32 apps
         where you had a menu bar, the working space, and then the status bar? */
div.contents {
    order: 2;
    overflow: scroll;
}

div.password-list-category {
    margin-left: 0.5em;
    margin-right: 1em;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}
div.password-list-entry {
    margin-left: 1em;
    margin-right: 1em;
    margin-top: 0.5em;
    margin-bottom: 1.0em;
    cursor: pointer;
}
div.password-list-entry > .entry-title {
}
div.password-list-entry > .entry-subtitle {
    font-size: 0.85em;
    color: #222222;
}

div.password-fields {
    display: flex;
    flex-flow: column;
    align-items: stretch;
    justify-content: center;
    margin-left: 0.5em;
    margin-right: 0.5em;
}
div.password-fields textarea {
    height: 10em;
}

div.passphrase-input {
    display: flex;
    flex-flow: column;
    align-items: stretch;
    margin-left: 0.5em;
    margin-right: 0.5em;
}

input, textarea {
    font-size: 16px;
}

button {
    font-size: 16px;
}

.hidden {
    display: none!important;
}
